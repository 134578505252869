import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import {
  Section,
  Title,
  Subtitle,
  ButtonGroup,
  DownloadButton,
} from "./styles"; // Customize styles here

const LandingPage = ({ t }: { t: TFunction }) => {
  return (
    <>
      <Section className="hero-section">
        <Container>
          <Row justify="center" align="middle">
            <Col lg={12} md={12} sm={24} xs={24}>
              <Title>{t("Ready2Play Tennis")}</Title>
              <Subtitle>{t("Find partners and courts to elevate your game!")}</Subtitle>
              <ButtonGroup>
                <DownloadButton href="/play-store-link">{t("Google Play")}</DownloadButton>
                <DownloadButton href="/app-store-link">{t("App Store")}</DownloadButton>
              </ButtonGroup>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <SvgIcon src="login.png" width="50%" height="auto" />
            </Col>
          </Row>
        </Container>
      </Section>

      {/* <Section className="features-section">
        <Container>
          <Title>{t("Features")}</Title>
          <Row gutter={[16, 16]}>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Feature>
                <FeatureIcon src="connect.svg" />
                <FeatureText>{t("Find and connect with nearby tennis players.")}</FeatureText>
              </Feature>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Feature>
                <FeatureIcon src="book.svg" />
                <FeatureText>{t("Book courts with ease from your phone.")}</FeatureText>
              </Feature>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Feature>
                <FeatureIcon src="stats.svg" />
                <FeatureText>{t("Track your game stats to improve over time.")}</FeatureText>
              </Feature>
            </Col>
          </Row>
        </Container>
      </Section> */}

      {/* <Section className="cta-section">
        <Container>
          <Title>{t("Ready to Play?")}</Title>
          <Subtitle>{t("Join thousands of tennis enthusiasts worldwide.")}</Subtitle>
          <ButtonGroup>
            <DownloadButton href="/play-store-link">{t("Google Play")}</DownloadButton>
            <DownloadButton href="/app-store-link">{t("App Store")}</DownloadButton>
          </ButtonGroup>
        </Container>
      </Section> */}
    <Section className="cta-section">
      <Container>
        <Row justify="space-between" align="middle">
      <Col lg={12} md={12} sm={24} xs={24}>
        <SvgIcon src="horizontal-logo.svg" width="150px" height="auto" />
      </Col>
      <Col lg={12} md={12} sm={24} xs={24} style={{ textAlign: 'right' }}>
        <p>&copy; {new Date().getFullYear()} Ready2Play. All rights reserved.</p>
        <p>Ready2Play is a trademark of Real Global Solutions Inc.</p>
      </Col>
        </Row>
      </Container>
    </Section>
    </>
  );
};

export default withTranslation()(LandingPage);
