import styled from "styled-components";

export const Section = styled.div`
  padding: 60px 20px;
  text-align: center;

  &.hero-section {
    background-color: #fff;
    color: white;
  }

  &.features-section {
    background-color: #fff;
  }

  &.cta-section {
    background-color: #1F2223;
    color: white;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 30px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const DownloadButton = styled.a`
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  background-color: #ff9800;

  &:hover {
    background-color: #e68a00;
  }
`;

export const Feature = styled.div`
  text-align: center;
`;

export const FeatureIcon = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 10px;
`;

export const FeatureText = styled.p`
  font-size: 1rem;
`;
